import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { FaCloudUploadAlt } from "react-icons/fa";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Upload,
  Layout,
  Alert,
  Typography,
  Spin,
} from "antd";
import { Col, Row } from "antd";
import TicketInformation from "./info";
import BlockList from "../../options/blocklist.json";
import SystemType from "../../options/systemtypes.json";
import moment from "moment";
const { TextArea } = Input;
const { Option } = Select;

const CreateTicket = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [form] = Form.useForm();
  const { Item } = Form;
  const [formValues, setformValues] = useState({
    name: "",
    phone: "",
    email: "",
    block: "",
    status: "pending",
    systemType: "",
    description: "",
  });

  const hanldeChange = (e) => {
    const { name, value } = e.target;
    setformValues({
      ...formValues,
      [name]: value,
    });
  };

  const storeInDatabase = () => {
    const payload = {
      ticketNo: Math.floor(Math.random() * 100000),
      customerDetails: {
        name: formValues.name,
        phone: formValues.phone,
        email: formValues.email,
      },
      block: formValues.block,
      status: formValues.status,
      systemType: formValues.systemType,
      description: formValues.description,
      createdAt:moment().format('DD-MM-YYYY,HH:MM:SS'),
      assignTo:"Mr.Irshad",
    };
    axios
      .post("http://localhost:3001/si-tickets", payload)
      .then((res) => {
        // console.log(res.data.data);
        setdata(res.data.data);
        form.resetFields();
        setformValues({
          name: "",
          phone: "",
          email: "",
          block: "",
          status: "pending",
          systemType: "",
          description: "",
        });
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Ticket created successfully`,
          text: `A confirmation email has been sent to your email address with ticket reference number #${res.data.data.ticketNo}.`,
          showConfirmButton: true,
          // timer: 1500
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (error.code == "ERR_NETWORK") {
          setIsLoading(false);
        }
      });
  };

  const onFinish = (values) => {
    //storing form data in database
    setIsLoading(true);
    console.log(formValues);
    setTimeout(() => {
      storeInDatabase();
    }, 1000);
  };

  return (
    <>
      <Layout className="createTicket-main main">
        <Row className="form-box" gutter={16}>
          <Col
            className={`form-container ${isLoading ? "blur" : ""}`}
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
          >
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Typography.Title level={2} style={{ textAlign: "center" }}>
                Fill the details below to create a ticket
              </Typography.Title>
              <Row gutter={16} style={{ marginTop: "40px" }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please enter a Name" }]}
                  >
                    <Input
                      className="form-input"
                      placeholder="Enter your name"
                      name="name"
                      value={formValues.name}
                      onChange={hanldeChange}
                      style={{ height: "50px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid phone number",
                        pattern: new RegExp(/^[0-9\b]+$/),
                        len: 10,
                      },
                    ]}
                  >
                    <Input
                      className="form-input"
                      placeholder="Enter your phone number"
                      name="phone"
                      value={formValues.phone}
                      onChange={hanldeChange}
                      style={{ height: "50px" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      className="form-input"
                      placeholder="Enter your email"
                      name="email"
                      value={formValues.email}
                      onChange={hanldeChange}
                      style={{ height: "50px" }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="subject"
                        rules={[{ required: true, message: "Please subject" }]}
                      >
                        <Input
                          className="form-input"
                          placeholder="subject"
                          name="subject"
                          value={formValues.subject}
                          onChange={hanldeChange}
                          style={{ height: "50px" }}
                        />
                      </Form.Item>
                    </Col> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="category"
                    rules={[{ required: true, message: "Please select block" }]}
                  >
                    <Select
                      placeholder="Select Block"
                      value={formValues.block}
                      onChange={(value) => {
                        setformValues({
                          ...formValues,
                          block: value,
                        });
                      }}
                      className="form-input"
                    >
                      {BlockList.map((item) => (
                        <Option value={item.value}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="systemType"
                    rules={[
                      {
                        required: true,
                        message: "Please select system type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select System Type"
                      value={formValues.systemType}
                      onChange={(value) => {
                        setformValues({
                          ...formValues,
                          systemType: value,
                        });
                      }}
                      className="form-input"
                    >
                      {SystemType.map((item) => (
                        <Option value={item.value}>{item.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    placeholder="Complaint Description"
                    col={12}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the description",
                      },
                    ]}
                  >
                    <Input.TextArea
                      rows={7}
                      placeholder="Complaint Description"
                      name="description"
                      value={formValues.description}
                      onChange={hanldeChange}
                    />
                  </Form.Item>
                </Col>
                {/* <Form.Item valuePropName="fileList">
                    <div className="upload-box">
                      <Upload action="/upload.do" {...props}>
                        <div className="upload">
                          Drag and Drop or Click Here
                          <div>
                            <FaCloudUploadAlt size={50} />
                          </div>
                        </div>
                      </Upload>
                    </div>
                  </Form.Item> */}
              </Row>
              <Form.Item className="create-button">
                <Button type="primary" htmlType="submit" size="large">
                  Create
                </Button>
              </Form.Item>
            </Form>
          </Col>

          <Col className="right-box" xs={24} sm={24} md={7} lg={7} xl={7}>
            <div className="right-box1"></div>
            <div className="right-box2"></div>
          </Col>
        </Row>
        {isLoading && <Spin className="loader" size="large" />}
      </Layout>
    </>
  );
};
export default CreateTicket;
