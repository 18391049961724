import { Button, Col, Form, Row, Typography, Select } from "antd";
import Input from "antd/es/input/Input";
import Layout from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Swal from 'sweetalert2'
import { useParams } from "react-router-dom";
const { Option } = Select;

export default function TicketInformation() {
  const params =useParams()
  const [replycomment, setreplyComment] = useState("");
  const [assignTo, setassignTo] = useState();
  const [ticketStatus, setticketStatus] = useState("");
  const [data, setdata] = useState();
  const [form] = Form.useForm();
  const admin = {
    name: "irshad",
  };
 
  //url for updating ticket in databse
  let updateUrl= `http://localhost:3001/si-tickets/${data?._id}`

  //url for updating response when admin replies
  let responseUrl= `http://localhost:3001/si-tickets/response/${data?._id}`

  // API for storing updated payload in the Database 
  const storeInDatabase = async (payload, url) => {
    try {
      axios.patch(url, payload);
      form.resetFields();
      setreplyComment("")
      return true
    } catch (err) {
      console.log(err);
    }
  };

  // after submiting form
  const onFinish = async (e) => {
    const payload = {
      text: replycomment,
      respondedBy: admin.name,
      respondedTimestamp: moment().format("MMMM Do YYYY, h:mm:ss a"),
    };
    let res= await storeInDatabase(payload,responseUrl);
    if(res){
       
         Swal.fire('Replied To Ticket')
    }
  };

  const getsingleTicketData = async () => {
    try {
      console.log(params._id)
      const response = await axios.get(`http://localhost:3001/si-tickets/${params._id}`);
      setdata(response.data.data);
      setdata(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const hanldeTicketStatus = (value) => {};

  //function for change the status
  const handleStatusChange = (value) => {
    setticketStatus(value);
    let payload = { status: value };
    storeInDatabase(payload,updateUrl);
  };

  //function for change Assign To
  const handleAssignChnage = (value) => {
    setticketStatus(value);
    let payload = { assignTo: value };
    storeInDatabase(payload,updateUrl);
  };

  const onFinishAssignTo = (value) => {};

  useEffect(() => {
    getsingleTicketData();
  }, [ticketStatus, assignTo,replycomment]);

  return (
    <div className="main">
      <Layout
        className="TicketInformation-container"
        style={{ background: "none" }}
      >
        <h1>Ticket Information</h1>
        <Row
          className="ticket-info-row"
          gutter={[16, 16]}
          justify="space-between"
        >
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Row justify="center" align="middle" className="ticket-information">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row
                  align="middle"
                  className="ticket-info"
                  justify="start"
                  gutter={[16, 16]}
                >
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Ticket No :</Typography.Text>
                    <span> {data?.ticketNo}</span>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Created at :</Typography.Text>
                    <span>{data?.createdAt}</span>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Assigned To : </Typography.Text>
                    <span>
                      <Typography.Text>{data?.assignTo}</Typography.Text>
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Status : </Typography.Text>
                    <Typography.Text>{data?.status} </Typography.Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Block : </Typography.Text>
                    <Typography.Text> {data?.block}</Typography.Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>System Type :</Typography.Text>
                    <Typography.Text>{data?.systemType}</Typography.Text>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Typography.Text strong>Created By :</Typography.Text>
                    <Typography.Text>
                      {data?.customerDetails.name}
                    </Typography.Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Typography.Text strong>
                     Replies
                    </Typography.Text>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Typography.Text >
                      { data?.responses?.map((el)=>(
                          <div key={el._id}>{el.text}</div>
                       ))}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center" align="middle" className="form-container">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form 
                form={form}
                onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label="Reply To the Ticket"
                    name="replyComment"
                    rules={[{ message: "Please Reply To the Ticket" }]}
                    style={{ fontSize: "14px", fontWeight: 500 }}
                  >
                    <Input
                      className="form-input"
                      placeholder="Add Comments"
                      name="subject"
                      value={replycomment}
                      onChange={(e) => setreplyComment(e.target.value)}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    valuePropName="fileList"
                    label="Upload Image/docs "
                  >
                    <div className="upload-box">
                      <Upload action="/upload.do" {...props}>
                        <div className="upload">
                          Drag and Drop or Click Here
                          <div>
                            {" "}
                            <FaCloudUploadAlt size={50} />
                          </div>
                        </div>
                      </Upload>
                    </div>
                  </Form.Item> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Reply
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={7} lg={7} xl={7}>
            <Row justify="center" align="middle" className="Addcomment">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title level={3}>Assign To</Typography.Title>
                <Form onFinish={onFinishAssignTo} layout="vertical">
                  <Form.Item
                    name="assignTo"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please select to whom you want to assign the ticket",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      className="form-input"
                      value={ data?.assignTo && data?.assignTo ||assignTo }
                      onChange={handleAssignChnage}
                    >
                      <Option value="block1">Block 1</Option>
                      <Option value="block2">Block 2</Option>
                      <Option value="block3">Block 3</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Assign
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row justify="center" align="middle" className="Addcomment">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Typography.Title level={3}>Status</Typography.Title>
                <Form onFinish={hanldeTicketStatus} layout="vertical">
                  <Form.Item
                    name="ticketStatus"
                    rules={[
                      {
                        required: true,
                        message: "Please select status",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      className="form-input"
                      name="ticketStatus"
                      value={ticketStatus}
                      onChange={handleStatusChange}
                    >
                      {/* <Option value="CLOSING-TASK">CLOSING TASK</Option> */}
                      <Option value="COMPLETED">COMPLETED</Option>
                      <Option value="DEPENDENCY-MATERIAL">
                        DEPENDENCY-MATERIAL
                      </Option>
                      <Option value="DEPENDENCY-PERMISSION">
                        DEPENDENCY-PERMISSION
                      </Option>
                      <Option value="IN-PROGRESS">IN PROGRESS</Option>
                      <Option value="PENDING">PENDING</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Change Status
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}
