import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { ConfigProvider } from "antd";
import './styles/style.scss'

function App() {
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins",
            colorPrimary: "#fdb913",
            colorTextPlaceholder: "#000",
          },
        }}
      >
        <AllRoutes />
      </ConfigProvider>
    </div>
  );
}

export default App;
