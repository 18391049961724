import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../components/header/Header";
import CreateTicket from "../pages/ticket/create";
import TicketInformation from "../pages/ticket/info";
import Home from "../pages/Home";

export default function AllRoutes() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/ticket-info" element={<TicketInformation />} /> */}
        <Route path="/create-ticket" element={<CreateTicket />} />
        <Route path="/dashboard/:_id" element={<TicketInformation/>} />
      </Routes>
    </div>
  );
}
