import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
const generateRandomData = () => {
  const data = [];
  for (let i = 1; i <= 10; i++) {
    data.push({
      ticketNo: `T00${i}`,
      systemType: "",
      title: `Issue ${i}`,
      createdAt: "2023-06-25",
      createdBy: `User ${i}`,
      assignTo: `Assigned ${i}`,
      status: "Open",
    });
  }
  return data;
};

const Dashboard = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const data = generateRandomData();
  // const csvData = [...data];
  const [csvData, setCsvData] = useState([]); //csvData is the data that we are going to download as csv
  const [unfilteredData, setUnfilteredData] = useState([]); //unfilteredData is the data that we are going to use for filtering
  const [ticketsData, setticketsData] = useState();
  const name = ticketsData?.customerDetails?.name;
  const navigate = useNavigate();

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    console.log("clearFilters", clearFilters);
    console.log("unfilteredData", unfilteredData);
    clearFilters();
    setSearchText("");
    setSearchedColumn(unfilteredData);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Ticket Id",
      dataIndex: "ticketNo",
      key: "ticketNo",
      width: "10%",
      ...getColumnSearchProps("ticketNo"),
    },
    {
      title: "System Type",
      dataIndex: "systemType",
      key: "systemType",
      width: "15%",
      ...getColumnSearchProps("systemType"),
    },
    {
      title: "block",
      dataIndex: "block",
      key: "block",
      width: "15%",
      ...getColumnSearchProps("block"),
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      ...getColumnSearchProps("createdAt"),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: `createdBy`,
      width: "15%",
      ...getColumnSearchProps(`createdBy`),
    },
    {
      title: "assign To",
      dataIndex: "assignTo",
      key: "assignTo",
      width: "20%",
      ...getColumnSearchProps("assignTo"),
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      ...getColumnSearchProps("status"),
    },
  ];

  //getting data from API
  const getTicketsData = async () => {
    try {
      const response = await axios.get("http://localhost:3001/si-tickets");
      // setticketsData(response.data.data);
      let ticketsData = [];
      response.data.data.map((item) => {
        ticketsData.push({
          ticketNo: item?.ticketNo,
          systemType: item?.systemType,
          block: item?.block,
          createdAt: item?.createdAt,
          createdBy: item?.customerDetails?.name,
          assignTo: item?.assignTo,
          status: item?.status,
          _id: item?._id,
        });
        // console.log(ticketsData);
        setticketsData(ticketsData);
        setUnfilteredData(ticketsData);
        setCsvData(ticketsData);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTicketsData();
  }, []);

  const clickedItem = async (item, index) => {
    console.log("item", item);
    navigate(`/dashboard/${item._id}`);
  };

  // console.log(ticketsData);

  return (
    <>
      <CSVLink data={csvData} filename="data.csv">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          style={{ margin: "16px 0", height: 50, float: "right" }}
        >
          Export to CSV
        </Button>
      </CSVLink>
      <Table
        columns={columns}
        dataSource={ticketsData && ticketsData}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              clickedItem(record, rowIndex);
            },
          };
        }}
      />
    </>
  );
};

export default Dashboard;
