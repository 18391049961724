import React from 'react'
import Dashboard from './ticket'

function Home() {
  return (
    <div className='main'>
       <Dashboard />
    </div>
  )
}

export default Home
