import React from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  AppstoreOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

const AppHeader = () => {
  return (
    <Header style={{ alignItems: "center", display: "flex" }}>
      <div className="logo" style={{ display: "flex" }}>
        <img
          src="https://safeinfratech.com/images/safelogo_colour.png"
          alt="logo"
          style={{ height: "50px" }}
        />
      </div>
      {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        <Menu.Item key="2" icon={<AppstoreOutlined />}>
          Products
        </Menu.Item>
        <Menu.Item key="3" icon={<UserOutlined />}>
          Profile
        </Menu.Item>
      </Menu> */}
    </Header>
  );
};

export default AppHeader;
